import React, { useCallback, useEffect } from 'react';
import ProfileForm from '../components/ProfileForm';
import FullPageLoader from '../components/FullPageLoader';
import { getCookie } from '../utils/cookieHelpers';
import { COOKIES, ENVIRONMENT } from '../constants';
import getEnvVariable from '../utils/environment';
import { Box } from '@twilio-paste/core';

export interface UnifiedUserInfo {
  given_name: string;
  family_name: string;
  email: string;
}

export const UnifiedLoginProfile = () => {
  const getUser = useCallback((): UnifiedUserInfo | undefined => {
    const cookie = getCookie(COOKIES.UNIFIED_USER_INFO);
    return cookie ? (JSON.parse(cookie) as UnifiedUserInfo) : undefined;
  }, []);

  const user = getUser();
  const isAuthenticated = !!getCookie(COOKIES.MAKO_AUTH);

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.replace(
        `${getEnvVariable(
          ENVIRONMENT.MAKO_URL
        )}/login?redirect_to=signup.sendgrid.com%2Funified_login%2Faccount_details`
      );
    }
  }, [isAuthenticated]);

  return isAuthenticated ? (
    <Box height={'100%'}>
      <ProfileForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        unifiedUser={
          user
            ? {
                firstName: user.given_name,
                lastName: user.family_name,
                email: user.email
              }
            : undefined
        }
      />
    </Box>
  ) : (
    <FullPageLoader />
  );
};
