import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withProfileInputs from '../containers/withProfileInputs';
import {
  alphabetizedCountryCodes,
  topCountryCodeOptions
} from '../constants/countryCodes';
import { trackOnce } from '../utils/Analytics';
import { AccountDetailsEvents } from '../analytics/accountDetailsEvents';
import { WriteSelectors } from './personalInfo-qa-hooks';
import {
  Input,
  Label,
  HelpText,
  Combobox,
  Grid,
  Column,
  Box
} from '@twilio-paste/core';
import { SearchIcon } from '@twilio-paste/icons/cjs/SearchIcon';

const trackCountryCodeCompleted = trackOnce();

const countryCodes = [...topCountryCodeOptions, ...alphabetizedCountryCodes];

export const PersonalInfo = (props) => {
  const defaultCountryCode = topCountryCodeOptions[0];
  const isUnifiedUser = Boolean(props.unifiedUser);
  const [countryCodeSearchResults, setCountryCodeSearchResults] =
    useState(countryCodes);

  useEffect(() => {
    props.setCountryCode(defaultCountryCode);
  }, []);

  const handleCountryCodeChange = (countryCode) => {
    trackCountryCodeCompleted(AccountDetailsEvents.countryCodeCompleted());
    props.setCountryCode(countryCode);
  };

  const onChangeCountryCodeInput = useCallback((input) => {
    const matchingCountryCodes = countryCodes.filter((countryCode) => {
      const lowerCaseInput = String(input).toLowerCase();
      return (
        countryCode.label.toLowerCase().includes(lowerCaseInput) ||
        countryCode.value.toLowerCase().includes(lowerCaseInput)
      );
    });
    setCountryCodeSearchResults(matchingCountryCodes);
  }, []);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      rowGap={'space60'}
      data-role="personal-info-container"
    >
      <Grid gutter={'space40'}>
        <Column>
          <Label htmlFor="first-name-id" required>
            First Name
          </Label>
          <Input
            id="first-name-id"
            required
            readOnly={isUnifiedUser}
            disabled={isUnifiedUser}
            onChange={(e) => props.setFirstName(e, e.target.value)}
            onBlur={(e) => props.blurFirstName(e, e.target.value)}
            value={props.profileInputs.firstName}
            hasError={Boolean(props.profileInputs.firstNameError)}
            data-role="first-name-id"
            {...WriteSelectors.firstNameInput}
          />
          {props.profileInputs.firstNameError && (
            <HelpText id="first-name-id-error-text" variant="error">
              {props.profileInputs.firstNameError}
            </HelpText>
          )}
        </Column>
        <Column>
          <Label htmlFor="last-name-id" required>
            Last Name
          </Label>
          <Input
            id="last-name-id"
            required
            readOnly={isUnifiedUser}
            disabled={isUnifiedUser}
            onChange={(e) => props.setLastName(e, e.target.value)}
            onBlur={(e) => props.blurLastName(e, e.target.value)}
            value={props.profileInputs.lastName}
            hasError={Boolean(props.profileInputs.lastNameError)}
            data-role="last-name-id"
            {...WriteSelectors.lastNameInput}
          />
          {props.profileInputs.lastNameError && (
            <HelpText id="last-name-id-error-text" variant="error">
              {props.profileInputs.lastNameError}
            </HelpText>
          )}
        </Column>
      </Grid>
      {isUnifiedUser && (
        <Grid gutter={'space40'}>
          <Column orientation={'vertical'}>
            <Label htmlFor="unified-email-id" required>
              Email
            </Label>
            <Input
              id="unified-email-id"
              required
              readOnly={isUnifiedUser}
              disabled={isUnifiedUser}
              value={props.unifiedUser.email}
              data-role="unified-email-id"
            />
          </Column>
        </Grid>
      )}
      <Grid gutter={'space40'}>
        <Column>
          <Label htmlFor="company-name-id" required>
            Company Name
          </Label>
          <Input
            id="company-name-id"
            required
            onChange={(e) => props.setCompanyName(e, e.target.value)}
            onBlur={(e) => props.setCompanyName(e, e.target.value)}
            value={props.profileInputs.companyName}
            hasError={Boolean(props.profileInputs.companyNameError)}
            data-role="company-name-id"
            {...WriteSelectors.companyNameInput}
          />
          {props.profileInputs.companyNameError && (
            <HelpText id="company-name-id-error-text" variant="error">
              {props.profileInputs.companyNameError}
            </HelpText>
          )}
        </Column>
        <Column>
          <Label htmlFor="website-id" required>
            Company Website
          </Label>
          <Input
            id="website-id"
            required
            onChange={(e) => props.setWebsite(e, e.target.value)}
            onBlur={(e) => props.blurWebsite(e, e.target.value)}
            value={props.profileInputs.website}
            hasError={Boolean(props.profileInputs.websiteError)}
            data-role="website-id"
            {...WriteSelectors.websiteInput}
          />
          {props.profileInputs.websiteError && (
            <HelpText id="website-id-error-text" variant="error">
              {props.profileInputs.websiteError}
            </HelpText>
          )}
        </Column>
      </Grid>
      {props.profileInputs.isReseller ? (
        <Grid gutter={'space40'}>
          <Column>
            <Label htmlFor="email-id" required>
              Contact Email
            </Label>
            <Input
              id="email-id"
              required
              onChange={(e) => props.setEmail(e, e.target.value)}
              onBlur={(e) => props.blurEmail(e, e.target.value)}
              value={props.profileInputs.email}
              hasError={Boolean(props.profileInputs.emailError)}
              data-role="email-id"
              {...WriteSelectors.companyEmailInput}
            />
            {props.profileInputs.emailError && (
              <HelpText id="website-id-error-text" variant="error">
                {props.profileInputs.emailError}
              </HelpText>
            )}
          </Column>
        </Grid>
      ) : null}
      <Grid gutter={'space40'}>
        <Column>
          <Combobox
            autocomplete
            required
            labelText="Country Code"
            items={countryCodeSearchResults}
            optionTemplate={(item) => <div>{item.label}</div>}
            itemToString={(item) => (item ? item.label : null)}
            initialSelectedItem={defaultCountryCode}
            onInputValueChange={({ inputValue }) => {
              if (inputValue) {
                onChangeCountryCodeInput(inputValue);
              }
            }}
            onSelectedItemChange={({ selectedItem }) =>
              handleCountryCodeChange(selectedItem)
            }
            insertAfter={
              <SearchIcon
                decorative={false}
                size="sizeIcon20"
                title="Search country codes"
              />
            }
            {...WriteSelectors.countryCodeSelect}
          />
        </Column>
        <Column>
          <Label htmlFor="phone-number-id" required>
            Phone Number
          </Label>
          <Input
            id="phone-number-id"
            type="tel"
            required
            onChange={(e) => props.setPhoneNumber(e, e.target.value)}
            onBlur={(e) => props.setPhoneNumber(e, e.target.value)}
            value={props.profileInputs.phoneNumber}
            hasError={Boolean(props.profileInputs.phoneNumberError)}
            data-role="phone-number-id"
            {...WriteSelectors.phoneInput}
          />
          {props.profileInputs.phoneNumberError && (
            <HelpText id="phone-number-id-error-text" variant="error">
              {props.profileInputs.phoneNumberError}
            </HelpText>
          )}
        </Column>
      </Grid>
    </Box>
  );
};

PersonalInfo.propTypes = {
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setCompanyName: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  setWebsite: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setCountryCode: PropTypes.func.isRequired,
  blurFirstName: PropTypes.func.isRequired,
  blurLastName: PropTypes.func.isRequired,
  blurCompanyName: PropTypes.func.isRequired,
  blurPhoneNumber: PropTypes.func.isRequired,
  blurWebsite: PropTypes.func.isRequired,
  blurEmail: PropTypes.func.isRequired,
  profileInputs: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    firstNameError: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    lastNameError: PropTypes.string,
    companyName: PropTypes.string.isRequired,
    companyNameError: PropTypes.string,
    phoneNumber: PropTypes.string.isRequired,
    phoneNumberError: PropTypes.string,
    website: PropTypes.string.isRequired,
    websiteError: PropTypes.string,
    email: PropTypes.string,
    emailError: PropTypes.string,
    isReseller: PropTypes.bool.isRequired
  }).isRequired,
  unifiedUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  })
};

export default withProfileInputs(PersonalInfo);
